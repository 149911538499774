<template>
  <slot name="header">
    <Header />
  </slot>

  <div id="main" :class="mainClass">
    <slot name="sidebar">
    </slot>
    <slot name="content">
    </slot>
  </div>
  <!-- <PulseLoader :loading="loading" :color="color" :size="size"/> -->
</template>

<script>
//import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Header from "@/components/Header.vue";
// import Footer from "@/components/Footer.vue";
export default {
  name: "FrameBase",
  props: ['mainClass',],
  data() {
    return {};
  },
  components: {
    Header,//Footer
    //PulseLoader
  },
};
</script>
