<template>
  <div class="header">
    <div class="menu col-sm-5">
      <ul>
        <li class="current_page_item">
          <router-link to="/">主页</router-link>
        </li>
        <!-- <li class="current_page_item"><router-link to="/about">About</router-link></li> -->
        <li>
          <router-link to="/study">学习</router-link>
        </li>
        <li class="dropdown">
          <router-link to="#" class="dropdown-toggle" id="dropdownMenul" data-toggle="dropdown">
            课程 <span class="caret"></span>
          </router-link>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenul">
            <template v-for="c in courses" v-bind:key="c">
              <li>
                <router-link v-if="user" :to="'/study?code=010100&&set=1&courseId=' + c.id">{{ c.bref }}</router-link>
                <a v-else :href="'https://www.codessp.cn/' + c.id + '_010000.html'">{{ c.bref }}</a>
              </li>
            </template>
            <!-- 
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=30">网页基础</router-link>
              <a v-else href="https://www.codessp.cn/study?code=010100&courseId=30">网页基础</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=2">JSP程序设计</router-link>
              <a v-else href="https://www.codessp.cn/2_010000.html">JSP程序设计</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=4">JavaScript程序设计(建设中)</router-link>
              <a v-else href="https://www.codessp.cn/4_010000.html">JavaScript程序设计(建设中)</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=5">JavaEE SSH程序设计</router-link>
              <a v-else href="https://www.codessp.cn/5_010000.html">JavaEE SSH程序设计</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=7">Bootstrap:一个CSS框架</router-link>
              <a v-else href="https://www.codessp.cn/7_010000.html">Bootstrap:一个CSS框架</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=10">前端框架VUE.js</router-link>
              <a v-else href="https://www.codessp.cn/10_010000.html">前端框架VUE.js</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=11">微信小程序</router-link>
              <a v-else href="https://www.codessp.cn/11_010000.html">微信小程序</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=12">SpringBoot</router-link>
              <a v-else href="https://www.codessp.cn/12_010000.html">SpringBoot</a>
            </li>
            <li>
              <router-link v-if="user" to="/study?code=010100&courseId=8">Java程序设计工程师考证</router-link>
              <a v-else href="https://www.codessp.cn/8_010000.html">Java程序设计工程师考证</a>
            </li> -->
          </ul>
        </li>

        <li v-if="user && user.username">
          <router-link to="/statistic">统计</router-link>
        </li>
        <li v-if="user && user.role == 9">
          <router-link to="/admin">管理</router-link>
        </li>
        <li v-if="user && (courseId == 31 || courseId == 1)" class="hidden-xs">
          <a href='javascript:void(0)' onclick="codeModal.click();">代码运行</a>
        </li>
      </ul>
    </div>
    <div class="nickname col-sm-2 text-center">
      <template v-if="user && user.username"><span class="text-info">你好，</span>
        <router-link class="text-info" to="/my">{{
          user.nickname
        }}</router-link>，<a href="javascript:void(0)" @click="logout">退出</a>
      </template>
      <template v-else><span style="color:white">你好，游客。</span><router-link to="/login">请登录</router-link></template>
    </div>

    <div class="logo hidden-xs col-sm-5 text-right">
      <h1>
        <a href="javascript:void(0)"><span class="java">Java Web</span>学习平台&nbsp;&nbsp;&nbsp;<img alt="" height="40"
            src="images/codessp.png" /></a>
      </h1>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import common from "@/js/common";
export default {
  name: "Header",
  // props: {
  //   username: {
  //     type: String,
  //     required: true,
  //     default: "abcd",
  //   },
  // },
  data() {
    return {
      user: null,
      urlPre: "https://www.codessp.cn/",
      courses: [],
      courseId: ""
    };
  },

  mounted() {
    // axios.get(Global.url + "/api/user").then((response) => {
    //   //console.log(response);
    //   if (response.status == "200") {
    //     this.user = response.data.data;
    //   }
    //   //console.log("user:"+this.user)
    // });
    //console.log("mounted");
    // on("login", (data) => {
    //   if (data == "true") {
    //     this.user = common.getUser();
    //   }
    // // });
    // let fakeLogin = this.$route.query.fakeLogin;

    // if (fakeLogin) {
    //   let t = sessionStorage.getItem("user1");
    //   if (t)
    //     this.user = t;
    //   else
    //     this.user = common.getUser();
    // sessionStorage.getItem("courseId1");
    //} else {
    this.user = common.getUser();
    this.courseId = this.$route.query.courseId;
    common.get("getCourses").then((res) => {
      //console.log(res.data.data);
      this.courses = JSON.parse(res.data.data);
    });
    //}
    //console.log(this.user);
  },
  computed: {},
  created() {
    //console.log("created");
  },
  methods: {
    logout: function () {
      sessionStorage.removeItem("isLogin");
      sessionStorage.removeItem("user");
      common.delCookie("username");
      //sessionStorage.removeItem("courseId");
      this.$router.push("/login");
      this.user = null;
    },
  },
};
</script>

<style scoped>
h1 {
  letter-spacing: -1px;
  font-size: 2.2em;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  margin-top: 0;
}

h2 {
  letter-spacing: -1px;
  font-size: 2em;
}

h3 {
  font-size: 1em;
}

.dropdown-menu li {
  display: block;
  border-radius: 3px;
  width: 230px;
  z-index: 1000;
}

.dropdown-menu li a {
  text-align: left;
  width: 230px;
  z-index: 1000;
}

.dropdown-menu {
  background: #2e2b25;
  z-index: 1000;
}

div.header {
  width: 100%;
  position: absolute;
  background: #000;
  /* url(../images/img01.jpg) repeat-x;*/
  line-height: 38px;
}

div.logo h1 a,
div.logo p {
  float: right;
  position: relative;
  right: 30px;
  text-transform: lowercase;
  font-family: "微软雅黑";
  color: #fff;
  padding: 5px;
  display: block;
  font-weight: bold;
}

div.logo p {
  margin: 0;
  padding: 14px 0 0 4px;
  line-height: normal;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.java {
  color: #95142a;
  font-family: courier new;
}

div.logo a {
  text-decoration: none;
  color: #d0c7a6;
}

.menu,
.logo,
.nickname {
  padding-left: 0;
  padding-right: 0;
}

div.menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 53px;
}

div.menu li {
  display: block;
  float: left;
  min-height: 53px;
}

div.menu a {
  height: 52px;
  display: block;
  padding: 0 20px 0px 20px;
  text-decoration: none;
  text-align: left;
  text-transform: lowercase;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #cec5a4;
  line-height: 52px;
}

div.menu .last {
  margin-right: 20px;
}

div.menu a:hover {
  color: #000;
  background: #ccc;
}

div.menu .current_page_item a {
  color: orange;
}

div.nickname a,
div.nickname span {
  line-height: 52px;
  height: 52px;
  color: orange;
  font-weight: bold;
  /*margin-left: 100px;float: left;*/
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
