<template>
  <Frame :contentClass="contentClass" :isSidebar="isSidebar">
    <template v-slot:sidebar> </template>
    <template v-slot:box1>
      <div class="row" style="border-bottom: 1px dotted;padding-bottom: 20px;">
        <div v-if="!username" class="nickname visible-xs col-xs-12"
          style="font-size: 10px; text-align: right;color:white">
          你好，游客。<router-link to="login">请登录,</router-link>
        </div>
        <img class="col-xs-6 col-xs-offset-3 col-sm-5 col-sm-offset-0" src="images/java.jpg" alt="" />

        <p v-if="mainInfo" class="col-xs-12 col-sm-7" style="margin-top:80px;">
          <span class="info">
            你正在学习<span class="bg-info">{{
              mainInfo.courseName
            }}</span></span>
          <span class="info">
            你上次学习到<router-link id="studyLink" :to="'study?courseId=' + mainInfo.courseId + '&code=' + mainInfo.code
                ">{{ mainInfo.chapterName }}</router-link></span><span class="info">已完成{{ percent * 100 }}%
            <progress max="1" v-bind:value="mainInfo.percent"></progress>
          </span>
        </p>
        <p v-else class="col-xs-12 col-sm-7">
          <br />
          你正在学习<span class="bg-info">Java程序设计</span> <br />
          <span>循序渐进，在线练习，自动批改，适合零基础的学习者</span>
          你还没任何学习记录哦，<router-link id="studyLink" to="study">点击这里</router-link>，开始你的Java之旅吧！
        </p>
      </div>

      <div class="row" style="padding-top: 20px;">
        <div class="col-md-5 hidden-xs">
          <ul class="imageList">
            <li class="first">
              <span>坚持每天学习一点</span><br />
              <span>扫码使用小程序，手机也可以学习java哦</span>
              <img style="
                    display: block;
                    width: 129px;
                    margin-left: auto;
                    margin-right: auto;
                  " src="images/codesspwx.jpg" />
            </li>
          </ul>
        </div>
        <div class="errorInfo col-md-7">
          <ul>
            <span v-if="mainInfo.cqcList" class="text-info">点击错误信息直达题目</span>
            <template v-for="cqc in mainInfo.cqcList" :key="cqc">
              <li class="first">
                {{
                  common.chapterNumber(
                    cqc.chapterQuizStudy.chapterQuiz.chapter.code
                  )
                }}{{ cqc.chapterQuizStudy.chapterQuiz.chapter.name }}
                {{
                  "第" +
                  cqc.chapterQuizStudy.chapterQuiz.queue +
                  "题 " +
                  cqc.chapterQuizStudy.chapterQuiz.title
                }}<br />
                <router-link class="info" :to="'/study?id=' + cqc.chapterQuizStudy.chapterQuiz.chapterId + '#q' + cqc.chapterQuizStudy.chapterQuiz.id
                    ">
                  {{
                    cqc.comment && cqc.comment.length > 50
                    ? cqc.comment.substring(0, 50)
                    : cqc.comment
                  }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </Frame>

  <!-- <Header />
  <Main /> -->
  <Footer />
</template>

<script>
// @ is an alias to /src
import Frame from "@/views/Frame.vue";
import Footer from "@/components/Footer.vue";
import common from "@/js/common";

export default {
  name: "Home",

  data() {
    return {
      mainInfo: "",
      username: "",
      contentClass: "content1 col-sm-10 col-xs-12",
      cqcList: "",
      common: "",
      isSidebar: false,
    };
  },
  components: {
    Frame,
    Footer,
  },
  mounted: function () {
    // // 发送POST请求并获取响应
    // axios.post('https://api.openai.com/v1/engines/davinci-codex/completions', {
    //   prompt: 'Hello,',
    //   max_tokens: 5,
    //   temperature: 0.7,
    //   n: 1,
    //   stop: '\n',
    //   options: { "parentMessageId": "chatcmpl-6vKa5glVD4p7QBZDfneokyRYrIne8" }
    // }, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer <your_api_key>'
    //   }
    // }).then(response => {
    //   console.log(response.data.choices[0].text);
    // }).catch(error => {
    //   console.error(error);
    // });

    //let fakeLogin = this.$route.query.fakeLogin;
    this.common = common;
    let t = this.common = common;

    //if (fakeLogin) {
    t = common.getUser();
    console.log(t);
    // sessionStorage.getItem("courseId1");
    //}

    if (t) {
      this.username = t.username;
      let courseId = common.getCourseIdFromSession();
      common.get("main", { username: this.username, courseId: courseId }).then((response) => {
        console.log(response);
        if (response.status == "200") {
          let t = JSON.parse(response.data.data);
          console.log(t);
          this.mainInfo = t;
          this.percent = new Number(t.percent).toFixed(2);
          //console.log(this.percent);
        }
      });
    }

  },
};
</script>
<style scoped>
#box1 {
  overflow: hidden;
  margin: 0 0 35px 0;
  border-bottom: 1px dotted;
}

#sidebar {
  height: 0;
  display: none;
}

#box2 {
  overflow: hidden;
  float: left;
}

#box3 {
  width: 300px;
  overflow: hidden;
  margin: 0 0 0 420px;
  outline: 1px solid #ccc;
  float: left;
}


#content #studyLink {
  font-weight: bold;
  color: #ad8667;
}

#main {
  position: relative;
  /*min-width: 1001px;*/
  color: #5e5e5e;
}

#main strong {
  color: #3e3e3e;
}

#main h2,
#main h3,
#main h4 {
  color: #242424;
}

#main ul {
  list-style: none;
}

#main ul li {
  border-top: dotted 1px #b3b3b3;
  padding-bottom: 20px;
}

#main ul li.first {
  padding-top: 0;
  border-top: 0;
  font-weight: bold;
}

#main ul li.first .info {
  padding-left: 0px;
  border-top: 0;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  color: red;
}

#main ul.imageList li {
  padding: 15px 0 15px 0;
}

p span.info {
  display: block;
  padding: 2px;
}

.errorInfo ul li {
  border: none;
}

.text-info {
  border-bottom: 1px solid #ccc;
  ;
}
</style>
