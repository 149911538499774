<template>
 <div class="footer hidden-xs" >
	<p class="legal">
		<a href="about.jsp">惠州市环森网络科技有限公司</a>©2016-{{year}}版权所有. <a
			href="http://beian.miit.gov.cn">粤ICP备09084978号-2</a><br /> •
		Design by <a href="http://www.freecsstemplates.org/">Free CSS
			Templates</a> • Icons by <a href="http://famfamfam.com/">FAMFAMFAM</a><br />
		<span style="color: red">资助我</span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="wx.html">微信</a>&nbsp;&nbsp;&nbsp;&nbsp;
		<a href="ali.html">支付宝</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="forex.html">财经日历</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="dzGames/" title="千款手游折扣！">缔造手游</a><br />
		<!-- <a
			href="https://webscan.360.cn/index/checkwebsite/url/www.codessp.com.cn"><img
			border="0"
			src="https://webscan.360.cn/status/pai/hash/f3b0c2ee2cd93a0be84e68467e9cc187" /></a> -->
	</p>
</div>
</template>

<script>
export default {
  name: 'Footer',
  data(){return {
    year:new Date().getFullYear()
  }}
}
</script>


<style scoped>
div.footer {
	clear: both;
	
	padding: 30px 0 30px 0;
	width:100%;
	margin-bottom: 0;
	background: #000; /*#757575 url(../images/img08.gif) repeat-x;*/
	z-index: 10;
	bottom: 0;
}

div.footer p {
	margin-bottom: 0em;
	text-align: center;
	line-height: normal;
	font-size: 0.8em;
	color: #BABABA;
}

div.footer a {
	padding: 0 20px;
	text-decoration: none;
	color: #DDDDDD;
}

div.footer a:hover {
	color: #FFFFFF;
}

div.footer .rss {
	background: url(../assets/img18.gif) no-repeat left center;
}

div.footer .xhtml {
	background: url(../assets/img19.gif) no-repeat left center;
}

div.footer .css {
	background: url(../assets/img20.gif) no-repeat left center;
}

div.footer .legal a {
	padding: 0;
}
</style>