<template>
  <FrameBase :mainClass="mainClass">
    <template v-slot:sidebar>
      <slot name="sidebar">
        <div id="sidebar" class="hidden-xs col-xs-12 col-sm-3" v-if="isSidebar"></div>
      </slot>
    </template>
    <template v-slot:content>
      <div id="content" :class="contentClass">
        <div id="box1">
          <slot name="box1"></slot>
        </div>
        <div id="box2">
          <slot name="box2"></slot>
        </div>
        <br class="clear" />
      </div>
      <br class="clear" />
    </template>
  </FrameBase>

  <!-- <div id="main" class="container">
    <slot name="sidebar">
      <div id="sidebar" class="hidden-xs col-xs-12 col-sm-3"></div>
    </slot>

    
  </div> -->
</template>

<script>
import FrameBase from "@/views/FrameBase.vue";
export default {
  name: "Frame",
  props: ["contentClass","isSidebar"],
  data() {
    return {
        mainClass:'container',
        //showSide:this.isSidebar?this.isSidebar:false
    };
  },
  components: {
    FrameBase,
  },
};
</script>

